import { useContext, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperCore, { Pagination } from 'swiper';
import ConnUrls from '../Connections/ConnectURL';
import { CurrentPContext, MasterContext, MobiOrNotContext } from '../ContextApi/context';

SwiperCore.use([Pagination]);


const MasterBootomBaners = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

  const [theList] = useState(!mobiOrNot ? master.bootomDeskTopBaners : master.bootomBaners);
  return (
    <>
      <div className={"spaceRightLong MbigFont bold SecondFont"}>
        עקבו אחרינו
      </div>
      <br />
      <div className={"BottomBannerImages"}>
        <Swiper
          modules={[Pagination]}
          className={"botBannerSwiper"}
          spaceBetween={10}
          slidesPerView={mobiOrNot ? 1 : 3}
          loop={false}
          height={mobiOrNot ? 500 : 500}
          // onSlideChange={() => console.log('slide change')}
          freeMode={false}
        >

          {
            theList.map((item, index) => {
              let lnk = item.txt && item.txt !== "" ? item.txt : "/";
              return (
                <SwiperSlide key={index} className={"WebPage HPTopImagesLst BottomMastBtn"}>

                  <a href={`${lnk}`} target={'_blank'}>
                    <div className={"HPTImgWrap"}>
                      <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                    </div>
                  </a>
                </SwiperSlide>
              )

            })
          }

        </Swiper>

      </div>
    </>
  )

}

export default MasterBootomBaners;



