import { ShoppingBag, SportsMotorsports } from "@mui/icons-material";

import AddressLine from "../web_pages/RestaurantPage/RestDetails/addressLine";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import parse from 'html-react-parser';




const getOrderTotalPrice = (theOrder) => {

    let tot = 0;

    tot = getOrderPrice(theOrder);

    //משלוח
    if (theOrder.OrderType == "Deliver") {
        tot += parseInt(theOrder.OrderDelPrice);
    }

    //קופון
    if (theOrder.orderKopon && theOrder.orderKopon !== "") {

        let koponPrice = (theOrder.orderKopon.KoponDiscType === '1')
            ? parseInt(theOrder.orderKopon.KoponDiscount) * -1
            : parseInt(((getOrderPrice(theOrder) / 100) * parseInt(theOrder.orderKopon.KoponDiscount)) * -1)

        tot += parseInt(koponPrice);
    }

    //זיכויים
    if (theOrder.orderZikoy && theOrder.orderZikoy.length > 0) {
        theOrder.orderZikoy.forEach(element => {
            let zikoyPrice = parseInt(element.zickoyPrice) * -1;
            tot += parseInt(zikoyPrice);
        });
    }

    return tot;
}


const getOrderPrice = (theOrder) => {
    let tot = 0;

    theOrder.Orders.map((item, index) => {
        let addsPrice = 0;

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                if (special.splitSelection != true) {
                    let freeCount = 0;

                    //בודק אם יש חינמים
                    special.spec.map((spec, specIndex) => {

                        if (special.freeAdds && parseInt(special.freeAdds) > 0) {

                            for (let i = 0; i < spec.itmsMount; i++) {
                                freeCount++;
                                if (freeCount <= parseInt(special.freeAdds)) {

                                }
                                else {
                                    if (parseFloat(spec.addToPrice) > -1) {
                                        addsPrice += (parseFloat(spec.addToPrice))
                                    }
                                }
                            }

                        }
                        else {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += (parseFloat(spec.addToPrice))
                                }
                            }
                        }
                    })
                }
                else {
                    addsPrice = getPizzaAddonsPrice(special, addsPrice);
                }
            })

        }

        tot += (parseFloat(item.prodPrice) + parseFloat(addsPrice)) * parseInt(item.mount);

    })
    tot += parseFloat(getStickedSauces(theOrder));

    return tot;
}

const getPizzaAddonsPrice = (special, addsPrice) => {

    let freeCount = 0; // Tracks the number of free items allocated
    let freeAdds = special.freeAdds ? special.freeAdds : 0

    // Separate full items
    let theFulls = special.spec.filter(it =>
        it.splitSelected == null ||
        it.splitSelected == "" ||
        it.splitSelected == "full"
    );

    // Separate split items
    let theSpls = special.spec.filter(it =>
        it.splitSelected != null &&
        it.splitSelected != "" &&
        it.splitSelected != "full"
    );

    // Process the fulls
    for (let i = 0; i < theFulls.length; i++) {
        freeCount++;
        if (freeCount > parseInt(freeAdds)) {
            addsPrice += parseFloat(theFulls[i].addToPrice); // Add price, rounded up
        }
    }

    // Check if freeCount is still within the limit after processing fulls
    if (freeCount < parseFloat(freeAdds)) {
        for (let i = 0; i < theSpls.length; i++) {
            freeCount += 0.5;

            if (freeCount > parseFloat(freeAdds)) {
                addsPrice += parseFloat(theSpls[i].addToPrice); // Add price, rounded up
            }
        }
    }
    else {
        for (let i = 0; i < theSpls.length; i++) {

            addsPrice += parseFloat(theSpls[i].addToPrice);
        }

    }

    return (addsPrice = Math.ceil(parseFloat(addsPrice)))
}


//בדיקת חינמים
const getPriceAfterFree = (freeAdds, fullsSelc, defaultprice) => {

    let priceAfterFix = defaultprice;

    if (fullsSelc / 2 <= freeAdds) {
        priceAfterFix = 0;
    }
    return (priceAfterFix)
}

// בדיקת חינמים בפיצה
const getPizzaPriceAfterFree = (theLst, theItem) => {

    //כמה שלמים
    let theFulls = theLst.filter(it =>
        it.splitSelected == null ||
        it.splitSelected == "" ||
        it.splitSelected == "full")

    //כמה חצויים
    let theSpls = theLst.filter(it =>
        it.splitSelected != null &&
        it.splitSelected != "" &&
        it.splitSelected != "full")

    //ריצה על כל השלמים
    for (let j = 0; j < theFulls.length; j++) {
        const element = theFulls[j];
        let sourcePrice = theItem.options.find((it, index) => it.servType == element.servType).addToPrice
        let theIndx = theLst.findIndex(ind => ind.servType == theFulls[j].servType)
        theLst[theIndx].addToPrice = sourcePrice.toString()
    }


    //ריצה על כל החצויים
    if (theSpls.length > 0 && theSpls.length % 2 == 0) {
        for (let i = 0; i < theSpls.length; i++) {
            let theIndx = theLst.findIndex(ind => ind.servType == theSpls[i].servType)
            let sourcePrice = theItem.options.find((it, index) => it.servType == theSpls[i].servType).addToPrice

            theLst[theIndx].addToPrice = (sourcePrice / 2).toString()
            //   theLst[theIndx].addToPrice = sourcePrice.toString()

        }

    }
    else if (theSpls.length > 0) {
        if (theSpls.length == 1) {
            let theIndx2 = theLst.findIndex(ind => ind.servType == theSpls[0].servType)
            let sourcePrice2 = theItem.options.find((it, index) => it.servType == theSpls[0].servType).addToPrice
            theLst[theIndx2].addToPrice = sourcePrice2.toString()
        }
        else {
            for (let i = 0; i < theSpls.length - 1; i++) {
                let theIndx = theLst.findIndex(ind => ind.servType == theSpls[i].servType)
                let sourcePrice = theItem.options.find((it, index) => it.servType == theSpls[i].servType).addToPrice

                theLst[theIndx].addToPrice = (sourcePrice / 2).toString()
                //   theLst[theIndx].addToPrice = sourcePrice.toString()

            }


            let theIndx2 = theLst.findIndex(ind => ind.servType == theSpls[theSpls.length - 1].servType)
            let sourcePrice2 = theItem.options.find((it, index) => it.servType == theSpls[theSpls.length - 1].servType).addToPrice
            theLst[theIndx2].addToPrice = sourcePrice2.toString()
        }

    }

    return (theLst)
}



const takeAwayBlock = (theTime, Address) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <ShoppingBag />
                </div>
                {/* <div className={"theImg"} style={{ backgroundImage: `url("/PermanentImages/take.png")` }}></div> */}
                <div className={"txtOrdType"}>

                    <div className={"bold pickOrDel"} >איסוף עצמי</div>
                    <div className={"regular"} >{theTime}</div>
                    {/* <div className={"regular"} ><AddressLine address={Address} typeDisp="NoIcon" /></div> */}

                </div>


            </div>
        </>
    )
}


const deliveryBlock = (theTime, thePrice) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <SportsMotorsports />
                </div>
                <div className={"txtOrdType"}>
                    <div className={"bold pickOrDel"} >משלוח</div>
                    <div className={"regular"} >{theTime}</div>
                    <div className={"regular"} >מחיר משלוח:  ₪{thePrice}</div>
                </div>
            </div>
        </>
    )
}

const theItemSpecials = (item) => {

    let totBk = (item.specials.length > 0) ?
        item.specials.map((special, specialIndex) => {

            let specCount = 0;
            let freeCount = 0;

            if (!special.splitSelection ||
                special.splitSelection == "full"
            ) {
                let specc = special.spec.map((spec, specIndex) => {
                    let addsPrice = 0;

                    if (spec.addToPrice > -1) {
                        specCount++;
                        //בודק אם יש חינמים
                        if (special.freeAdds != null && parseInt(special.freeAdds) > 0) {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                freeCount++;
                                if (freeCount <= parseInt(special.freeAdds)) {
                                }
                                else {
                                    if (parseFloat(spec.addToPrice) > -1) {
                                        addsPrice += parseInt(spec.addToPrice) * item.mount
                                    }
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += (parseFloat(spec.addToPrice))
                                }
                            }
                        }


                        return (
                            <div key={specIndex} className={"ordItmLine ordItmOptHead"}>
                                <span className={"rSideMiddle"}>

                                    <span className={"regular"}> {spec.servType}</span>
                                    <span className={"bold"}>
                                        {item.mount > 1 ? `  (* ${item.mount})` : ""}</span>
                                </span>

                                <span className={"bold lSideSmall"}>
                                    {addsPrice > 0 ? "₪ " + (addsPrice) + " +" : ""}
                                </span>
                            </div>
                        )
                    }
                })
                if (specCount > 0) {
                    return (
                        <div key={specialIndex} className={"ordItmSpecLine spacePadSmall"}>
                            <div className={"ordItmOptHead spacePadSmall bold"} >{special.optionName}</div>
                            {specc}
                        </div>
                    )
                }
            }
            else {
                return (
                    <div key={"pizz1"} className={"ordItmLine ordItmOptHead"}>
                        <span className={"rSideMiddle"}>
                            {
                                parse(getPizzaSplits(special, []).join(""))
                            }
                        </span>

                        <span className={"bold lSideSmall"}>
                            {`₪ ${getPizzaAddonsPrice(special, 0)}` + " +"}
                        </span>
                    </div>

                )

            }


        })
        : ""
    return totBk;
}

const clearOrd = () => {
    let ord = { restId: "", currentP: [], Orders: [] };

    let stringOrd = JSON.stringify({ ...ord });
    sessionStorage.setItem("am_order", stringOrd)

    return (ord);

}

//מושך את חלוקת הפיצה לפי התוספות שנבחרו
const getPizzaSplits = (ord, rowsDisp) => {

    let lHalf = [];
    let rHalf = [];
    let rtQuart = [];
    let rbQuart = [];
    let ltQuart = [];
    let lbQuart = [];
    let full = [];

    for (let i = 0; i < ord.spec.length; i++) {

        if (ord.spec[i].splitSelected != null) {
            const getSPl = {
                lHalf: () => lHalf.push(ord.spec[i].servType),
                rHalf: () => rHalf.push(ord.spec[i].servType),
                rtQuart: () => rtQuart.push(ord.spec[i].servType),
                rbQuart: () => rbQuart.push(ord.spec[i].servType),
                ltQuart: () => ltQuart.push(ord.spec[i].servType),
                lbQuart: () => lbQuart.push(ord.spec[i].servType),
                full: () => full.push(ord.spec[i].servType)
            }

            getSPl[ord.spec[i].splitSelected]();
        }
    }

    //פונקציה פנימית
    const pushNewString = (placeTxt, arrySplt) => {
        rowsDisp.push(`<span class="headORdTxt">
        <span class="regular bold">
        ${placeTxt}
         </span>
            ${arrySplt.join(" + ")}
        <span/><br/>`);
    }

    if (full.length > 0) { pushNewString("כל המגש", full); }
    if (rHalf.length > 0) { pushNewString("חצי ימין", rHalf) }
    if (rtQuart.length > 0) { pushNewString("רבע ימין עליון", rtQuart) }
    if (rbQuart.length > 0) { pushNewString("רבע ימין תחתון", rbQuart) }
    if (lHalf.length > 0) { pushNewString("חצי שמאל", lHalf) }
    if (ltQuart.length > 0) { pushNewString("רבע שמאלי עליון", ltQuart) }
    if (lbQuart.length > 0) { pushNewString("רבע שמאלי תחתון", lbQuart) }


    return (rowsDisp)
}

//תוכן חלוקת המגש - בפיצריות
const pizzConten = (spec, item) => {

    if (spec.splitSelected != null) {

        let bk = "";
        if (spec.splitSelected != null) {
            const getSPl = {
                lHalf: "חצי שמאל",
                rHalf: "חצי ימין",
                rtQuart: "רבע ימין עליון",
                rbQuart: "רבע ימין תחתון",
                ltQuart: "רבע שמאל עליון",
                lbQuart: "רבע שמאל תחתון",
                full: "כל המגש"
            }

            bk = getSPl[spec.splitSelected];
        }
        else {
            bk = "כל המגש";
        }
        return (<span className={"regular"}>{bk}</span>)
    }
    else {
        return (<span className={"regular"}>{spec.itmsMount > 0 ? `X ${spec.itmsMount} ` : ""}</span>)
    }

}


//בודק אם יש רטבים מוצמדים ואם הלקוח עבר את המכסה החינמית
//במידה ועבר, יוחזר ההפרש שיש לתשלום 
const getStickedSauces = (theOrder) => {
    let priceToAdd = 0;
    let fSauces = (theOrder.freeSaucess != null) ? theOrder.freeSaucess : 0;
    let countIt = 0;
    theOrder.StickedSauces?.forEach(sauce => {

        for (let i = 0; i < sauce.mount; i++) {
            countIt++;
            if (countIt > fSauces) {
                priceToAdd += parseFloat((sauce.addToPrice != "") ? sauce.addToPrice : 0);
            }
        }

    });

    return priceToAdd;

}



//אופן תשלום
const payingTYpe = (payt) => {

    switch (payt) {
        case "1":
            return "אשראי";
        case "2":
            return "מזומן";
        case "3":
            return "ביט";
    }
}

export default {
    getOrderTotalPrice, getOrderPrice,
    takeAwayBlock, deliveryBlock, clearOrd, getStickedSauces,
    pizzConten,
    theItemSpecials,
    getPizzaPriceAfterFree,
    getPriceAfterFree,
    payingTYpe,
    getPizzaSplits


};